
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stays img{width:16px;height:16px;}
.stays button{border:1px solid rgba(0,0,0,0.7);border-radius:15px;}
.stays p.sub-title{font-weight:600;font-size:20px;}
/* .container_custom .mapSection{position:relative;}
.container_custom .mapComponent{
  position: fixed;
  width:100%;
  top: 0;height:100%;} */


.multi_img_sec
{
  position: relative;
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-column-gap: 5%;
}
.multi_img_sec  .img_div_multi
{
  border: 1px dashed gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;



}
.multi_img_sec img
{
  max-width: 100%;
  display: block !important;
  margin-bottom: 20px;
  padding: 20px;
  margin: 20px auto;
}

@media only screen and (max-width:575px)
{
  .table_filter
{
  max-width: 100%;
}

  .upimages.upimages_edit img
  {
    min-width: 68px !important;
    max-height: 68px !important;
    min-height: 68px !important;
    max-width: 68px !important;
  }
  .upphoto button
  {
    max-width: 100% !important;
  }
  .multi_img_sec
  {
    grid-template-columns: 45% 45%;
    grid-column-gap: 5%;
  }
}



@media only screen and (max-width:375px)
{
  .dash-state
  {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)) !important;
  }
}
.mobiletog.btn-link
{
  color: #ec6043 !important;
}

@media only screen and (max-width:575px)
{
  .btn_view_oneline.px-4
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .btn_view_oneline.px-4 .btn.me-3
  {
    margin-right: 0.25rem !important;
  }
}