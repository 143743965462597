:root{
  --themeclr: #072d52;
  --second: #ec6043;
  --txtgray: #7a7b7d;
  --bordclr: #b0b0b0;
  --textgray: #484848;
  --adminblue:#ec6043;
}
.shadowbox{
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
}
*{
    padding: 0;
    margin: 0;
  }
  body{
    font-family: 'Roboto', sans-serif;
  }
  li{
    list-style-type: none;
  }
  .liststyledots li{
    list-style-type: disc;
  }
  .textgray{
    color: var(--textgray)
  }
  .fw-500{
    font-weight: 500;
  }
  select.form-control {
    appearance: auto;
}
  .themebg {
    background-image: linear-gradient(45deg, #082e53, #f6be09);
  }
  .mh-100vh{
    min-height: 100vh;
  }
  .mh-100vh-header{min-height: calc(100vh - 138px);}
  .mh-100vh-filterheader-footer{    min-height: calc(100vh - 252px);}
.themeclr{
  color: var(--themeclr);
}
  .themehr {
      background-color: #858585;
  }
.nounder{
  text-decoration: none;
}
.nolink{
  cursor: auto !important;
}
.upper{
  text-transform: uppercase;
}
/* common */
.themebord{
  border-color:#dadada ;
}
.graytxt {
  color: #717171;
}
.flex-1 > * {
  flex: 1;
}
.jc-end{
  justify-content: end;
}
.jc-center{
  justify-content: center;
}
.jc-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.btn.disable {
  cursor: not-allowed !important;
}
a.navbar-brand img,.logo img {

  max-width: 145px !important;

}

.br-10 {
  border-radius: 10px;
}
.form-switch .form-check-input {
  height: 20px;
}
p{
  margin-bottom: 0;
}
.bord {
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
}
.mb-85{
  margin-bottom: 85px;
}
.mt-78{
  margin-top: 78px;
}
.f-50{
  font-size: 50px;
}
.form-check {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.form-check-input:checked {
  background-color: var(--themeclr);
  border-color: var(--themeclr);
}
.countbtns button:hover {
  border-color: gray;
}
button.nofocus:focus{
  box-shadow: none !important;
}
.mb-90{
  margin-bottom: 90px;
}
.height-100{
  height: 100%;
}
.min-height-100vh{
  min-height: 100vh;
}
.w-full {
  width: 100%;
}
input::placeholder{
  font-size: 14px;

}
.fw-500{
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.f-14{
  font-size: 14px;
}
.f-12{
  font-size: 12px;
}
.btn-theme{
  background: var(--themeclr);
  color:var(--second);
}
.themeclr{
  color: var(--themeclr)
}
.txtgray{
  color:var(--txtgray)
}
.bordclr{
  color:var(--bordclr)
}
.btn-theme{
  background-color: #ec6043;
  color: #fff;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 0px;
  border-color: #ec6043;
}
.btn-theme:hover{
    background-color: #212529;
    border-color: #212529;
    color:#fff;
}
.btnbord {
  border-radius: 8px;
}
select:focus, input:focus, textarea:focus{
  box-shadow: none !important;
}
.csvbtn button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  pointer-events: none;
  font-size: 12px;
}
.csvbtn {
  width: 100px;
  overflow: hidden;
  position: relative;
  border: 1px solid #dbdada;
  border-radius: 5px;
}

.note-group-select-from-files {
  display: none;
}

/* admin css */

#dashboard {
  display: flex;
  gap: 20px;
}
#sidebar {
  width: 200px;
  box-shadow: 14px 0px 7px -1px silver;
  border-radius: 0 10px 10px 0;
  position: absolute;
  background-color: #fff;
  transition: 0.5s;
  height: 100vh;
  position: fixed;

}
#sidebar ul li > span {
  padding-left: 38px;
  display: flex;
}
.showsidebar .adminsmalllogo {
  display: none;
}
.showsidebar .adminlogo {
  display: unset;
}
.adminlogo {
  display: none;
}
.adminlogo
{
  height: 70px;
}
.adminsmalllogo{
  height: 30px;
}
.hidesidebar .sidebar ul span {
  display: none;
}
.hidesidebar #sidebar {
  width: 43px;
  transition: 0.5s;
}
.ham {
  height: 14px;
}
.sidebar{
  position: relative;
}
.sidebar ul {
  padding-left: 0;
  margin-top: 25px;
  height: calc(100vh - 160px);
  overflow-y: auto;
}
.sidebar ul li a:after {
  content: "";
  height: 100%;
  width: 0%;
  background: transparent;
  position: absolute;
  left: 0;
  top:0;
  z-index: -1;
  transition: 0.2s
}

.sidebar ul li:hover a:after,
.sidebar ul li a.active{
  width: 100%;
  background: var(--themeclr);
}
.sidebar ul li:hover span,
.sidebar ul li a.active span{
  color : #fff;
}

.sidebar ul li:hover img,
.sidebar ul li a.active img{
 filter:brightness(0) invert(1);
}
.sidebar ul li a {
  padding: 10px 0;
  padding-left: 12px;
  z-index: 9;
  position: relative;
  display: flex !important;
  align-items: center;
  text-decoration: none !important;
  width: 100%;
}
.sidebar ul li img{
  margin-right: 10px;
  max-width: 20px;
  filter: brightness(0.5);
}
.sidebar ul li span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.hidesidebar .togglebtn {
  transform: rotate(178deg);

}
.showsidebar .togglebtn {
  transform: rotate(0);
}
button.togglebtn {
  transition: 0.5s;
  position: absolute;
  top: 65px;
  right: -9px;
  background: white;
  border: none;
  box-shadow: 0 0 5px silver;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.tabtable .nav-tabs{    border-bottom: none;}
.rdt_Table * {
font-size: 16px;
}
.row.photos img {
width: 100%;
}
.admin-forms .nav.nav-tabs {
width: 100%;
height: 43px;
overflow-x: auto;
flex-flow: nowrap;
}
.password  input{
  border-right: 0;
  border-radius: 0.25rem 0 0 0.25rem;
}
.password .form-floating {
  width: calc(100% - 50px);
}

.note-popover .popover-content .note-dropdown-menu, .note-editor .note-toolbar .note-dropdown-menu {
  min-width: 177px;
}
.note-modal .modal-header {
  flex-direction: column;
}
.note-btn-group .dropdown-toggle::after{
  display: none;
}
.note-editor.fullscreen {
  background: #fff;
}
.note-modal input[type="checkbox"]{
  margin-right: 5px;
}
.password button {
  width: 50px;
  border-left: 0;
  border-color: #ced4da;
}
.admin-forms .nav.nav-tabs li{
white-space: nowrap;
}
.adminheader {
  box-shadow: 0px 1px 5px #d0d0d0;
}
.adminheader, .cont {
  /* width: calc(100vw - 18px); */
  padding-left: 230px;
  transition: 0.5s;
  padding-right: 28px;
}
.headandcont{
  /* width: 100vw; */
  width: 98vw;
  margin-left: auto;
  margin-right: auto;

}
.hidesidebar .adminheader,.hidesidebar .cont{
  /* width: 100vw; */
  padding-left: 70px;
  transition: 0.5s;

} 
.adminheader {
  height: 72px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 5px;
}
#dashboard .rdt_TableHeadRow {
  color:#072d52;
  font-weight: 600;
}
.adminblue{
  color:var(--adminblue)
}
.circleprog {
  height: 100px;
  width: 100px;
  background: #4565b0;
  background-image: conic-gradient( #4565b0 344deg , #dedcdc 0deg);
  border-radius: 100%;
  position: relative;
}
.circleprog .overlay {
  position: absolute;
  height: 76px;
  width: 76px;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
span.title.timer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.dash-state {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));;
  column-gap:20px;
  row-gap: 20px;
}
.gradbox {
  padding: 21px;
  background-image: linear-gradient(45deg, #fff 27%, #ede9e9);
}
.date-pic .react-datepicker {
  width: 100%;
}
.date-pic .react-datepicker__header {
  width: 100%;
  background: transparent !important;
  border: 0 !important;
}
.date-pic .react-datepicker__month {
  width: 98%;
}
.date-pic .react-datepicker__month-container{
  float:unset !important
}
.date-pic .react-datepicker__week,.date-pic .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}
.date-pic .react-datepicker__week > div {
  /* flex: 1 1; */
  width: 40px !important;
  /* background: red; */
  /* border-radius: 50%; */
  aspect-ratio: 1/1;
  line-height: 3;
}
.date-pic .react-datepicker{
  border: 0 !important;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}

.admin-forms .form-floating>.form-control:focus~label,
/* .admin-forms .form-floating>.form-control:not(:placeholder-shown)~label,  */
.admin-forms .form-floating>.form-select~label 
{
  opacity: 1 !important;
  transform: scale(0.85) translateY(-0.7rem) translateX(1.15rem) !important;
  background: #fff;
  color: gray;
  text-align: left;
  display: inline-block;
  padding: 0;
  height: 20px;
}
.outlinebtn {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
}
.admin-forms label {
  color: gray;
}
.mobileadminlogo {
  height: 32px;
  display: none;
}
.mobiletog{
  display: none;
}
.admin-prof img{
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.admin-prof .show>.btn-success.dropdown-toggle,
.admin-prof .btn-success:hover{
  background-color: transparent;
}
.admin-prof button{
  background-color: transparent;
  border: 0;
}
.admin-prof button:after{
  display: none;
}
@media(max-width:767px){
  .adminheader, .cont{
    padding-left: 30px;
  }
  .mobileadminlogo {
    display: block;
}
.mobiletog{
  display: block;
}
  #sidebar {
    width: 100%;
    z-index: 100;
  }
  button.togglebtn {
    right: 6px;
    top: 63px;
  }
  .hidesidebar #sidebar{
    width: 100%;
  }
  .hidesidebar .sidebar ul span {
    display: inline-block;
}
  .showsidebar #sidebar {
    left: -130px;
  }
  .showsidebar .adminsmalllogo{
    display: none;
  }
  .hidesidebar .adminlogo{
    display: inline-block;
  }
  .adminsmalllogo{
display: none;
  }
  .showsidebar  #sidebar{
    width: 0;
  }
  
.hidesidebar .adminheader, .hidesidebar .cont {
    padding-left: 20px;
}
  
}
.h-55 select, .h-55 input, .spaninput input, .spaninput select{
  height: 55px;
}

.upimages img {
  max-width: 80px;
    object-fit: cover;
    margin-right: 10px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.upphoto button img{
  max-height: 143px;
    aspect-ratio: 1.5/1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin:auto;
}

.upphoto button {
  position: relative;
  border: 1px dashed gray;
  min-height: 145px;
  flex: 1;
  aspect-ratio: 1.5/1;
}
.upphoto button span {
  font-size: 50px;
  color: gray;
}
.upphoto button p {
  color: gray;
  font-size: 14px;
}
.upphoto button input{    
  position: absolute;
  height: 100%;
  width: 100%;
  background: red;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9999;
}
.textbox {
  max-width: 100%;
  margin: auto;
  background: #fff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 2px;
}
.chatmsg img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  margin-right: 15px;
}
.chats {
  height: calc(100vh - 290px);
  position: relative;
  overflow-y: scroll;
}

.replymsg img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  margin-left: 15px;
}
.chats{
  margin-bottom: 0px;
}
.replymsg,.chatmsg{
   max-width: 500px;
   padding-right: 20px;
   margin-bottom: 15px;
}
.replymsg p,.chatmsg p{
   font-size: 15px;
}
.replymsg {
  text-align: right;
 
  margin-left: auto;
}

.as-react-table .row{
  margin-left: 0;
  margin-right: 0;
}
.tableview .table-striped>tbody>tr:nth-of-type(odd)>*{
  --bs-table-accent-bg: #fff;
}
.tableview .table-bordered>:not(caption)>*>*{
  border-width: 0;
}
.tableview .table>:not(:first-child){
  border-width: 0;
}
.tableview th {
  font-size: 15px;
}
.tableview  .asrt-table-head{
  margin-bottom: 40px;
}
.tableview td {
  font-size: 14px;
  color: gray !important;
}
.tableview .col-md-6.float-right.text-right {
  text-align: right;
}
.tableview .page-item a {
  color: #ec6043 !important;
}
.tableview .disabled.page-item a {
  color: #d2d2d2 !important;
}
.tableview span.input-group-text {
  font-size: 14px;
}
.tableview .table-foot {
  font-size: 14px;
}
.bluebg {
  background: #074687;
}
/* .adminlogin{
  background-image: url("../images/loginbg.jpg");
} */


/* / table / */

.table-foot {
font-size: 14px;
}
thead tr th {
white-space: nowrap;
}
.as-react-table .table-body .col-md-12 {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
}
.col-md-6.float-right.text-right input {
margin-bottom: 6px;
}
.table-foot .col-md-6 {
min-width: 216px;
margin-bottom: 10px;
}

.table-head .col-md-6:first-child {
width: 155px;
}
.table-head .col-md-6:last-child {
flex: 1;
}
.asrt-table-head .col-md-6:last-child {
text-align: right;
}
.asrt-table-head .col-md-6{
margin-top: 5px;
}
.table_tools button{
margin-right: 5px;
background: transparent;
color: gray;
border-color: #ced4da;
}
table {
font-size: 14px;
}
tbody {
border-top: 0 !important;
}
.btn-warning {
background: #e88e26;
border-color: #e88e26;
color: #fff;
}
.btn-success {
background: #48af48;
border-color: #48af48;
color: #fff;
}

.btn-trans {
background: transparent;
border: 0;
text-align: left;
}

tr td .btn {
margin-right: 1px;
background: var(--themeclr) !important;
border-color: var(--themeclr) !important;
box-shadow: none !important;
}
tr td .btn:hover {
background: var(--second);
border-color: var(--second);
}


.themescroll::-webkit-scrollbar-track{
background: gray;
border-radius: 5px;
}
.nav-item .nav-link.active {
color: var(--adminblue);
box-shadow: -2px -2px 5px #e7e7e7;
}
.nav-item .nav-link {
color:#000;
}
.themescroll::-webkit-scrollbar-thumb {
background: var(--themeclr);
border-radius: 5px;
}
.themescroll::-webkit-scrollbar {
width: 5px;
height: 5px;
}
.themescroll.hidescroll::-webkit-scrollbar{
width: 0;
}
.themescroll{
scrollbar-color: gray var(--themeclr)!important;
scrollbar-width: thin !important;

}
tr td.action {
  white-space: nowrap;
}
.confirm input {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border:1px solid #ced4da;
}
.close_modal 
{
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25) !important;
}

a, .btn-link
{
  color:#0d6efd !important;
}
a:hover, .btn-link:hover
{
  color:#ec6043 !important;
}

.table th, .table th.sortable
{
  color:#072d52 !important;
}

.btn-primary:hover
{
  background-color: #ec6043;
  border-color: #ec6043;

  color:#fff;
}
.btn-outline-dark:hover
{
  background-color: #ec6043;
  border-color: #ec6043;

  color:#fff;
}

.btn-outline-dark
{
  background-color: #212529;
  border-color: #212529;
  border-radius: 0px;
  color:#fff;
}
.input-group.asrt-page-length

{
align-items: center;
}

.btn-primary.disabled, .btn-primary:disabled
{
  background-color: #222 !important;
  border-color: #222 !important;

}

.hidesidebar .sidebar ul li img
{
margin-left: -5px;
max-width: 80%;
}

.page-link
{
  color:#000 !important;
}
.page-link:hover
{
  color:#ec6043 !important;
}
.dropdown-toggle.btn-success:focus
{
  background-color: transparent !important;
  border-color: transparent !important;

  box-shadow: 0 0 0 0rem rgba(72, 180, 97, 0.5) !important;
}
.upimag_sidue.upimages img
{
max-width: 150px !important;
}
.rdt_TableCell,.rdt_TableCol,.rdt_Table *
{
  font-size: 14px !important;
}
.rdt_TableCol
{
  color: #072d52 !important;
}
.amenity_table_hide_head .rdt_TableHeadRow
{
display: none;
}
.mobiletog.btn-link
{
  color: #ec6043 !important;
}

.instant_bookinsw label
{
min-width:200px;
}

@media only screen and (max-width:575px)
{
  .btn_view_oneline.btn_view_oneline_mor_bt .btn
  {
    min-width: 100% !important;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:576px) and (max-width:991px)
{
  .btn_view_oneline.btn_view_oneline_mor_bt .btn.me-3
  {
  margin-right: 5px !important;
  }
  .btn_view_oneline.btn_view_oneline_mor_bt
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}